@use "variable/color";
@use "variable/breakpoint";

.page.details {
  section {
    color: color.$black;

    .title {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      padding-bottom: 1rem;

      font-size: 18px;
      font-weight: 700;

      border-bottom: 1px solid color.$black;
    }
  }
}