@use '../variable/form' as form;

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    padding-bottom: .5rem;

    font-size: form.$label-size;
    font-weight: form.$label-weight;

    color: form.$label-color;
  }

  & > app-validation-handler, & > .text-danger {
    padding-top: .5rem;
    font-size: .8125rem;
  }
}

@mixin set-invalid() {
  --input-color: #{form.$input-invalid-color};
  --input-border-color: #{form.$input-invalid-color};
  --input-placeholder-color: #{form.$input-invalid-color};

  & ~ app-validation-handler {
    display: block;
  }
}

form.validation-after-submit.ng-invalid.ng-submitted {
  .form-group {
    div:has(.ng-invalid:not(:disabled,.ng-select-disabled)),
    .ng-invalid:not(:disabled,.ng-select-disabled) {
      @include set-invalid;
    }
  }
}

form.validation-after-touch.ng-invalid.ng-touched {
  .form-group {
    div:has(.ng-invalid.ng-dirty:not(:disabled,.ng-select-disabled)),
    .ng-invalid.ng-dirty:not(:disabled,.ng-select-disabled) {
      @include set-invalid;
    }
  }
}
