@use "vendor/bootstrap" as bootstrap;
@use "variable/color";

body, html {
  font-size: 16px;

  .text-danger {
    color: bootstrap.$danger !important;
  }

  .cut-long-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p {
    color: color.$black;
  }
}
