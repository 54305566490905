@import "palette";

// Colors
$primary: $primary-500;
$grey: $gray-500;
$red: $red-300;
$green: $green-500;

$black: #494850;
$white: #FFF;
$orange: #FFC100;

$success: $green;
$danger: $red;

$vis-grey-light: #D8D8F6;
$vis-red-light: #FFF1E9;
$vis-green-light: #F2F9EB;
$vis-red: #FE6E26;
$vis-green: #7CBF36;