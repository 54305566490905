// Palette
$primary-500: #006BCB;

$gray-300: #616876;
$gray-400: #6D7584;
$gray-500: #798293;
$gray-600: #868F9E;
$gray-700: #A1A8B3;
$gray-800: #E4E6E9;
$gray-900: #F6F6FF;

$green-300: #1F8659;
$green-400: #239664;
$green-500: #27A76F;
$green-600: #3DB07D;
$green-700: #52B98C;
$green-800: #A9DCC5;
$green-900: #E9F6F1;

$red-300: #B64652;
$red-400: #CD4E5C;
$red-500: #E45766;
$red-600: #E76875;
$red-700: #EC8994;
$red-800: #F7CDD1;
$red-900: #FCEEF0;

$grays: (
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
);

$greens: (
  'green-300': $green-300,
  'green-400': $green-400,
  'green-500': $green-500,
  'green-600': $green-600,
  'green-700': $green-700,
  'green-800': $green-800,
  'green-900': $green-900,
);

$reds: (
  'red-300': $red-300,
  'red-400': $red-400,
  'red-500': $red-500,
  'red-600': $red-600,
  'red-700': $red-700,
  'red-800': $red-800,
  'red-900': $red-900,
);
